import React, { useState, useEffect } from 'react';
import { CursorActions } from '../../context/CursorContext';

const AngelCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const {isHovering, setIsHovering, isHolding, setIsHolding} = CursorActions()

  useEffect(() => {
    const updateMousePosition = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    const updateTouchPosition = (e) => {
      const touch = e.touches[0];
      setPosition({ x: touch.clientX, y: touch.clientY})
    }

    window.addEventListener('mousemove', updateMousePosition);
    window.addEventListener('touchstart', updateTouchPosition)

    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
      window.removeEventListener('touchstart', updateTouchPosition);
    };
  }, []);

  return (
    <div className={`md:inline-block hidden select-none angel-cursor bg-invert-blur z-[99999] fixed rounded-[100%] shadow shadow-white border border-white/50 -translate-x-6 -translate-y-6 shadow-md w-12 h-12 flex justify-center items-center`} style={{ left: position.x, top: position.y }}>
        <div className={`absolute top-0 left-0 transition-all duration-1000 bg-white ${isHovering ? 'animate-ping opacity-100' : 'opacity-0 w-full h-full'} rounded-[100%] w-full h-full`}>
        </div>
        <p className={`hidden md:block transition-all absolute text-center translate-y-20 font-primary glowing-purple-text text-3xl text-white w-32 duration-1000 ${isHovering ? 'opacity-100' : 'opacity-0'}`}>Press & Hold</p>

    </div>
  );
}

export default AngelCursor;
